import { Controller } from "@hotwired/stimulus"
import { Idiomorph } from "idiomorph/dist/idiomorph.esm.js"

const dataAttribute = "data-expandable-section-component-open-value"
const sectionSelector =
  "[data-turbo-frame-component-target='expandableSection']"

export default class extends Controller {
  static targets = ["expandableSection"]

  persistExpandableSectionStates(event) {
    if (event.target.id != this.element.id) return
    const newSections = event.detail.newFrame.querySelectorAll(sectionSelector)
    this.expandableSectionTargets.forEach(currentSection => {
      const xpath = this.#getXPathForElement(currentSection)
      const newSection = [...newSections].find(newSection => {
        return this.#getXPathForElement(newSection) === xpath
      })
      if (!newSection) return
      newSection.setAttribute(
        dataAttribute,
        currentSection.getAttribute(dataAttribute)
      )
    })
    event.detail.render = (currentElement, newElement) => {
      Idiomorph.morph(currentElement, newElement.children, {
        morphStyle: "innerHTML"
      })
      const morphEvent = new CustomEvent("morph", {
        bubbles: true,
        cancelable: false
      })
      this.element.dispatchEvent(morphEvent)
    }
  }

  // #shouldAddElement = node => {
  //   return !(
  //     node.id &&
  //     node.hasAttribute("data-turbo-permanent") &&
  //     document.getElementById(node.id)
  //   )
  // }

  // #shouldMorphElement = (oldNode, _newNode) => {
  //   console.log("should morph", oldNode)
  //   if (oldNode instanceof HTMLElement) {
  //     return !oldNode.hasAttribute("data-turbo-permanent")
  //   } else {
  //     return true
  //   }
  // }

  // #shouldRemoveElement = node => {
  //   return this.#shouldMorphElement(node)
  // }

  #getXPathForElement(element, container = this.element.parentElement) {
    if (element === container) {
      return "."
    }

    let path = ""
    while (element && element !== container) {
      let index = 1
      let sibling = element.previousSibling

      while (sibling) {
        if (sibling.nodeName === element.nodeName) {
          index++
        }
        sibling = sibling.previousSibling
      }

      path = "/" + element.tagName.toLowerCase() + "[" + index + "]" + path
      element = element.parentNode
    }

    return path
  }
}
